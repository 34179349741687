/** @jsx jsx*/
import { css, jsx, keyframes } from '@emotion/core'

const spinning = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359.99deg);
  }
`

const LoadingIndicator = ({ children }) => (
  <div css={css`
    height: 30px;
    width: 30px;
    border: 4px solid #EEEEEE;
    border-top: 4px solid grey;
    border-radius: 50%;
    animation: ${spinning} 1s ease infinite; 
  `}
  />
)

export default LoadingIndicator