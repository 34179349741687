import React from "react"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionBlock from "../components/page-elements/SectionBlock"
import { P } from "../components/html/Fonts"
import FormContact from "../components/forms/ContactForm"
import { ImgNickClaraBoxVertical } from "../components/images/ImgNickClaraBoxVertical"

const ContactPage = () => (
  <Layout>
    <SEO 
      title="Send us Your Questions, Stories or Testimonies" 
      description="If you have any questions, stories, or testimonies, we would love to hear from you. Please contact us by using the form below."
      keywords={[]} 
    />
    <SectionBlock title="Contact" img={<ImgNickClaraBoxVertical />}>
      <P>If you have any questions, stories, or testimonies, we would love to hear from you.</P>
      <P>Please contact us by using the form below.</P>
      <FormContact />
    </SectionBlock>
  </Layout>
)

export default ContactPage
