import styled from '@emotion/styled'
import { baseStyles, formStyles } from './_config.js'


// ------ Form Styles


export const Form = styled.form`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 ${baseStyles.baseMargin}px 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export const FormGroupingFull = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: left;
`

export const FormGroupingHalf = styled.div`
  position: relative;
  display: inline-block;
  width: calc(50% - 15px);
  text-align: left;
`

export const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0 15px 0 0;
  font-size: ${formStyles.labelFontSize * 0.75}rem;
  font-family: ${formStyles.labelFontFamily};
  font-weight: ${formStyles.labelFontWeight};
  font-style: ${formStyles.labelFontStyle};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export const Input = styled.input`
  position: relative;
  width: 100%;
  margin: 0 0 15px 0;
  padding: 5px 10px;
  border: 1px solid ${baseStyles.colorQuaternary};
  font-size: ${formStyles.inputFontSize * 0.75}rem;
  font-family: ${formStyles.inputFontFamily};
  font-weight: ${formStyles.inputFontWeight};
  font-style: ${formStyles.inputFontStyle};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export const TextArea = styled.textarea`
  position: relative;
  width: 100%;
  min-height: 200px;
  margin: 0 0 15px 0;
  padding: 5px 10px;
  border: 1px solid ${baseStyles.colorQuaternary};
  font-size: ${formStyles.textareaFontSize * 0.75}rem;
  font-family: ${formStyles.textareaFontFamily};
  font-weight: ${formStyles.textareaFontWeight};
  font-style: ${formStyles.textareaFontStyle};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export const ErrorList = styled.ul`
  display: inline-block;
  width: 100%;
  margin: 0 0 ${baseStyles.baseMargin}px 0;
  text-align: left;
`
export const ErrorItem = styled.li`
  color: #FF2357;
  font-size: ${formStyles.errorFontSize * 0.75}rem;
  font-family: ${formStyles.errorFontFamily};
  font-weight: ${formStyles.errorFontWeight};
  font-style: ${formStyles.errorFontStyle};
`