import React from 'react'
/** @jsx jsx*/
import { css, jsx } from '@emotion/core'
import { Form, FormGroupingFull, FormGroupingHalf, Input, Label, ErrorList, ErrorItem, TextArea } from '../html/Forms';
import { Button2 } from '../html/Buttons';
import LoadingIndicator from '../animations/LoadingIndicator';
import { baseStyles } from '../html/_config';

// Encode Function for Data
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class FormContact extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      Subject: 'Website Contact Form',
      FName: '',
      LName: '',
      Email: '',
      Message: '',
      
      errors: [],
      submitting: 0,
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  handleChange = e => {
    const { target } = e
    
    if(target.value.length > 0) {
      if(target.nextSibling.style.color.length > 0) {
        target.nextSibling.removeAttribute('style')
      }
      if(target.localName === 'input') {
        target.nextSibling.style.top = '3px'
        target.nextSibling.style.fontSize = '0.725em'
      }
      if(target.localName === 'textarea') {
        target.nextSibling.style.top = '3px'
        target.nextSibling.style.fontSize = '0.725em'
      }
    } 
    else {
      target.nextSibling.removeAttribute('style')
    }

    this.setState({ [target.name]: target.value });
  };

  validate = () => {
    const formLength = this.formEl.length
    let errors = []

    if (this.formEl.checkValidity() === false) {

      for(let i=0; i<formLength; i++) {
        const elem = this.formEl[i]
        if (elem.nodeName.toLowerCase() !== 'button') {
          if (!elem.validity.valid) {
            errors.push('Error on field ' + elem.previousSibling.innerHTML + ': ' + elem.validationMessage)
          }
        }
      }

      this.setState({errors})
      return false;
    } else {
      this.setState(errors)
      return true
    }
  }

  onSubmit = e => {
    e.preventDefault()
    this.setState({submitting: 1})

    if(this.validate()) {
      const form = e.target
      let data = {...this.state}

      delete data.errors
      delete data.submitting

      fetch('/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...data
        })
      })
      .then(() => {
        this.setState({submitting: 2})
      })
      .catch(error => {
        this.validate()
        this.setState({submitting: 1})
      })
    } else {
      this.setState({submitting: 0})
    }
  }

  render() {
    return (
      <Form ref={form => this.formEl = form} onSubmit={this.onSubmit} name='Contact' noValidate data-netlify="true" netlify-honeypot="bot-field">

        {this.state.errors.length > 0 && 
          <ErrorList>
            {this.state.errors.map((error, i) => (
              <ErrorItem key={i}>
                {error}
              </ErrorItem>
            ))}
          </ErrorList>
        }

        <FormGroupingHalf>
          <Label htmlFor='FName'>First Name</Label>
          <Input type='text' name='FName' onChange={this.onChange} required/>
        </FormGroupingHalf>
        <FormGroupingHalf>
          <Label htmlFor='LName'>Last Name</Label>
          <Input type='text' name='LName' onChange={this.onChange} required/>
        </FormGroupingHalf>
        <FormGroupingFull>
          <Label htmlFor='Email'>Email</Label>
          <Input type='email' name='Email' onChange={this.onChange} required/>
        </FormGroupingFull>
        <FormGroupingFull css={css`display: none;`}>
          <Label htmlFor='BotMessage'>If you are human don't fill this form out</Label>
          <Input name='bot-field' onChange={this.onChange} required/>
        </FormGroupingFull>
        <FormGroupingFull>
          <Label htmlFor='Message'>Message</Label>
          <TextArea name='Message' onChange={this.onChange} required/>
        </FormGroupingFull>
        <FormGroupingFull css={css`text-align: right; button { margin-right: 0; }`}>
          <Button2 
            type='submit'
            css={css`
              ${this.state.submitting === 2 ? `background: ${baseStyles.colorSecondary};` : ``}
              ${this.state.submitting === 2 ? `border: 1px solid ${baseStyles.colorSecondary};` : ``}
          `}>
            {this.state.submitting === 0 && 
              'Send'
            }
            {this.state.submitting === 1 && 
              <LoadingIndicator/>
            }
            {this.state.submitting === 2 && 
              <>
                Sent
                <span css={css`
                  line-height: 1.25; 
                  font-size: 1.25rem;
                  margin-left: 5px;
                `}>
                  &#10003;
                </span>
              </>
            }
          </Button2>
        </FormGroupingFull>
      </Form>
    )
  }
}